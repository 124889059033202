<template>
  <div class="custom-button-container">
    <el-row :gutter="20">
      <el-col :span="10">
        <!-- Settings Panel -->
        <el-form :model="buttonProps">
          <el-form-item label="Text">
            <el-input
              v-model="buttonProps.text"
              placeholder="Button Text"
              maxlength="20"
            ></el-input>
          </el-form-item>

          <el-form-item label="Background Color">
            <el-color-picker
              v-model="buttonProps.backgroundColor"
            ></el-color-picker>
          </el-form-item>

          <el-form-item label="Text Color">
            <el-color-picker v-model="buttonProps.textColor"></el-color-picker>
          </el-form-item>

          <el-form-item label="Border Radius">
            <el-input-number
              v-model="buttonProps.borderRadius"
              :min="0"
            ></el-input-number>
          </el-form-item>

          <el-form-item label="Button Position">
            <el-select
              v-model="buttonProps.position"
              placeholder="Select Position"
            >
              <el-option label="Bottom" value="bottom"></el-option>
              <!-- <el-option label="Right" value="right"></el-option> -->
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>

      <el-col :span="14">
        <!-- Button Preview Panel -->
        <div :style="previewStyle" class="button-preview">
          <div>
            <el-button :style="buttonStyle">{{ buttonProps.text }}</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <span class="dialog-footer" slot="footer">
      <el-button
        style="padding: 10px"
        type="primary"
        :disabled="checkIsDisabled()"
        @click="saveConfigs()"
        class="mr-1"
        >save</el-button
      >
    </span>
  </div>
</template>
  
  <script>
export default {
  props: {
    initialProps: {
      type: Object,
      default: () => ({
        text: "Search",
        backgroundColor: "#409EFF",
        textColor: "#ffffff",
        borderRadius: 5,
        position: "bottom",
      }),
    },
  },
  data() {
    return {
      buttonProps: { ...this.initialProps },
    };
  },
  computed: {
    buttonStyle() {
      return {
        backgroundColor: this.buttonProps.backgroundColor,
        color: this.buttonProps.textColor,
        borderRadius: `${this.buttonProps.borderRadius}px`,
      };
    },
    previewStyle() {
      return {
        textAlign: this.buttonProps.position,
      };
    },
  },
  methods: {
    checkIsDisabled() {
      return !(
        this.buttonProps.backgroundColor &&
        this.buttonProps.text &&
        this.buttonProps.textColor
      );
    },
    saveConfigs() {
      this.$emit("saveButtonConfigs", this.buttonProps);
    },
  },
};
</script>
  
  <style scoped>
.custom-button-container {
  padding: 20px;
}

.button-preview {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
.dialog-footer {
  width: 100%;
  display: flex;
  float: right;
  background-color: #eee;
  justify-content: end;
  border-top: 1px solid #ccc;
}
</style>
  